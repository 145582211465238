import React from "react";
import BannerSection from "../components/Charities/BannerSection";
import CharitableBasicsSection from "../components/Charities/CharitableBasicsSection";
import ClaimCharitySection from "../components/Charities/ClaimCharitySection";
import GetGiftsSection from "../components/Charities/GetGiftsSection";
import SupportPeopleSection from "../components/Charities/SupportPeopleSection";
import Layout from "../components/Layout/Layout";

const pageInfo = {
  parent_path: "/solutions",
  path: "/charities",
  title: "Helping donors support your charity",
  description:
    "If you're a registered charity in Canada, donors can give to you through Charitable Impact. See how it works and get gifts faster by claiming your charity page.",
};
const Charities = () => {
  return (
    <Layout pageInfo={pageInfo}>
      <BannerSection />
      <SupportPeopleSection />
      <CharitableBasicsSection />
      <GetGiftsSection />
      <ClaimCharitySection />
    </Layout>
  );
};

export default Charities;
