import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import ButtonWithAnchor from "../Buttons/ButtonWithAnchor";

const SectionWrap = styled.section`
  overflow: hidden;
  background-color: #27ccd1;
  border-radius: 4px;
  padding: 102px 0;
`;

const ContentBox = styled.div`
  background-color: #f5f8fc;
  border-radius: 4px;
  padding: 70px 40px;
  position: relative;
  text-align: center;

  &::before,
  &::after {
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: -67px;
    content: "";
    height: 200px;
    position: absolute;
  }

  &::before {
    background-image: url(/for-charities/box-dot.png);
    right: -62px;
    width: 180px;

    @media only screen and (max-width: 767px) and (min-width: 320px) {
      display: none;
    }
  }

  &::after {
    background-image: url(/for-charities/claim-left_2.png);
    left: -62px;
    width: 150px;
    z-index: 999;

    @media only screen and (max-width: 767px) and (min-width: 320px) {
      bottom: -39px;
      top: -33px;
      left: -32px;
      width: 18%;
      height: 114px;
      z-index: 9;
    }
  }
`;

const StyledH2 = styled.h2`
  color: #424242;
  font-size: 24px;
  font-weight: 300;
  line-height: 29px;
  margin: 0 auto;

  @media only screen and (max-width: 420px) {
    line-height: 26px;
  }
`;

const ClaimCharityBtn = styled.div`
  margin: 15px 0 0;
`;

const StyledButtonWithAnchor = styled(ButtonWithAnchor)`
  &::before {
    display: none;
  }
`;

const EmailImg1 = styled.img`
  position: absolute;
  right: -132px;
  top: 127px;
`;

const EmailImg2 = styled.img`
  position: absolute;
  right: 0;
  top: 273px;
`;

const ClaimCharitySection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col md={12}>
            <ContentBox>
              <StyledH2>
                Customize your charity page on Charitable <br /> Impact and get
                gifts by direct deposit:
              </StyledH2>
              <ClaimCharityBtn>
                <StyledButtonWithAnchor
                  text="Claim your charity page"
                  href="https://my.charitableimpact.com/claim-charity"
                  className="my-2 my-sm-0"
                />
              </ClaimCharityBtn>
              <span className="animate__animated animate__fadeInLeft animate__slow animate__delay-1s pe-1">
                <EmailImg1
                  src="/for-charities/claim-right-bottom.png"
                  className="d-none d-md-block"
                />
              </span>
              <span className="animate__animated animate__fadeInLeft animate__slow animate__delay-1s pe-1">
                <EmailImg2
                  src="/for-charities/claim-right-bottom-mobile.png"
                  className="email-img-4 d-md-none"
                />
              </span>
            </ContentBox>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default ClaimCharitySection;
