import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { StyledH2 } from "../../styles/globalStyles";
import ButtonWithLink from "../Buttons/ButtonWithLink";

const SectionWrap = styled.section`
  margin: 0;
  padding: 80px 0;
  background-color: #f5f8fc;
`;

const Styled_H2 = styled(StyledH2)`
  font-family: ProximaNova-Light;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
`;

const StyledPara = styled.p`
  color: #282828;
  font-family: ProximaNova-Regular;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 30px;
`;

const StyledHr = styled.hr`
  border: 0.5px solid #c799d1;
  opacity: 1;
`;

const LearnBtn = styled.div`
  margin: 30px 0 0;
`;

const StyledButtonWithLink = styled(ButtonWithLink)`
  border-radius: 50px;
  padding: 8.5px 42px;
  font-family: ProximaNova-Regular;

  &::before {
    display: none;
  }
`;

const BasicsList = styled.div``;

const BasicsListItem = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1.14285714em;
  list-style-position: outside;
  list-style-type: none;
  margin-bottom: 25px;
  padding: 0.21428571em 0;
  table-layout: fixed;
`;

const Content = styled.div`
  color: #67686c;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 18px;
  line-height: 24px;
  margin-left: 1rem;
  margin-top: 10px;
`;

const Image = styled.img`
  display: inline-block;
  height: 4em;
  margin-top: 5px;
  width: 4em;
`;

const Number = styled.div`
  color: #424242;
  font-family: ProximaNova-Medium;
  font-size: 18px;
  padding: 0 5px 0 0;
`;

const ContentText = styled.div`
  color: #282828;
  float: left;
  font-size: 16px;
  font-family: ProximaNova-Regular;
`;

const CharitableBasicsSection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row className="justify-content-center">
          <Col md={12} lg={10} xl={10}>
            <Row>
              <Col
                md={5}
                data-sal="fade"
                data-sal-duration="1000"
                data-sal-delay="400"
              >
                <Styled_H2>
                  Charitable Impact is a public foundation and operates as a
                  donor-advised fund
                </Styled_H2>

                <StyledPara>
                  Donors on Charitable Impact can support any registered charity
                  and manage their charitable giving from a single account. We
                  call it the Impact Account, and it's like an online bank
                  account for charitable giving.
                </StyledPara>
                <StyledHr className="d-none d-md-block" />
                <LearnBtn className="d-none d-md-block text-center">
                  <StyledButtonWithLink
                    text="Learn more about how it works"
                    Linkto="/how-it-works"
                    className="my-2 my-sm-0"
                  />
                </LearnBtn>
              </Col>

              <Col md={7}>
                <BasicsList>
                  <BasicsListItem>
                    <Image src="/for-charities/basics-icon1.svg" />
                    <Content>
                      <Number>01.</Number>
                      <ContentText>
                        Donors add money to their Impact Account and get a tax
                        receipt from Charitable Impact. Then, they can take time
                        and space to find charities that match their interests
                        while they plan the impact they want to make.
                      </ContentText>
                    </Content>
                  </BasicsListItem>

                  <BasicsListItem>
                    <Image src="/for-charities/basics-icon2.svg" />
                    <Content>
                      <Number>02.</Number>
                      <ContentText>
                        Donors give to the charities of their choice from their
                        Impact Account.
                      </ContentText>
                    </Content>
                  </BasicsListItem>

                  <BasicsListItem>
                    <Image src="/for-charities/basics-icon3.svg" />
                    <Content>
                      <Number>03.</Number>
                      <ContentText>
                        Charitable Impact sends gifts from donors to charities
                        weekly for those charities signed up with direct
                        deposit, and twice monthly for charities receiving gifts
                        by cheque.
                      </ContentText>
                    </Content>
                  </BasicsListItem>

                  <StyledHr className="d-md-none" />

                  <LearnBtn className="d-md-none text-center">
                    <StyledButtonWithLink
                      text="Learn more about how it works"
                      Linkto="/how-it-works"
                      className="my-2 my-sm-0"
                    />
                  </LearnBtn>
                </BasicsList>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default CharitableBasicsSection;
