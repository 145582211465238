import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { StyledH2, StyledH3 } from "../../styles/globalStyles";

const SectionWrap = styled.section``;

const YellowBg = styled.span`
  img {
    position: absolute;
    width: auto;

    @media only screen and (max-width: 1400px) {
      display: none;
    }
  }
`;

const DotImage = styled.span`
  position: absolute;
  top: -10px;
  left: 266px;
  width: auto;
  padding: 0;

  img {
    @media only screen and (max-width: 1400px) {
      display: none;
    }
  }
`;

const BottomRoundImage = styled.span`
  left: -36px;
  position: absolute;
  top: 195px;
  width: auto;
  padding: 0;

  img {
    @media only screen and (max-width: 1400px) {
      display: none;
    }
  }
`;

const GetGiftFaster = styled(Row)`
  padding: 93px 0;

  .col-md-6,
  .col-lg-10,
  .col-md-12 {
    position: relative;
  }
`;

const LaptopLeft = styled(Col)``;

const GetGiftFasterContent = styled(Col)`
  margin: 60px 0 120px !important;

  @media only screen and (max-width: 767px) {
    text-align: center;
    margin: 60px 0 30px !important;
  }
`;

const Styled_H3 = styled(StyledH3)`
  font-size: 18px;
  line-height: 28px;
  margin: 0 0 15px;
  letter-spacing: inherit;
`;

const WhenYouClaim = styled(Col)`
  margin: 0;
  text-align: left;
`;

const Styled_H2 = styled.h2`
  color: #282828;
  font-family: ProximaNova-Semibold;
  font-size: 20px;
  padding: 0 0 27px;

  @media only screen and (max-width: 767px) {
    text-align: center;
  }
`;

const ListWrap = styled.div``;

const ListItem = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1.14285714em;
  list-style-position: outside;
  list-style-type: none;
  margin-bottom: 20px;
  padding: 0.21428571em 0;
  table-layout: fixed;

  &::before {
    background-color: #663387;
    color: #fff;
    content: "\\2713";
    display: inline-block;
    height: 17px;
    line-height: 21px;
    margin: 5px 10px 0 0;
    min-width: 17px;
    text-align: center;
    border-radius: 2px;
  }
`;

const Content = styled.div`
  line-height: 24px;
  font-size: 16px;
`;

const ListItemData = [
  {
    text: "Set up direct deposit and receive gifts on a weekly basis, rather than twice monthly by cheque",
  },
  {
    text: "Get notified when you receive a gift from a donor",
  },
  {
    text: "Download monthly transaction reports to see gift details",
  },
  {
    text: "Edit your charity description, contact information, and logo on your Charitable Impact page",
  },
  {
    text: "Immediately thank donors who give to you, even if they've chosen to give anonymously",
  },
];

const GetGiftsSection = () => {
  return (
    <SectionWrap>
      <YellowBg className="d-none d-md-block">
        <img src="/for-charities/gift-bg.png" />
      </YellowBg>

      <Container>
        <GetGiftFaster className="justify-content-md-center">
          <Col md={12} lg={10}>
            <Row>
              <DotImage className="d-none d-md-block">
                <img src="/for-charities/gift-dot.svg" alt="" loading="lazy" />
              </DotImage>
              <LaptopLeft md={6}>
                <img
                  src="/for-charities/get-gift.png"
                  className="img-fluid"
                  alt=""
                  loading="lazy"
                />
              </LaptopLeft>
              <BottomRoundImage className="d-none d-md-block">
                <img
                  src="/for-charities/gift-bottomcurve.svg"
                  alt=""
                  loading="lazy"
                />
              </BottomRoundImage>
              <GetGiftFasterContent
                md={6}
                data-sal="fade"
                data-sal-duration="1000"
                data-sal-delay="100"
              >
                <StyledH2>
                  Get gifts faster and customize your charity page
                </StyledH2>
                <Styled_H3>
                  Claiming your charity page allows you to customize how you
                  appear on Charitable Impact and helps you get gifts faster
                  through direct deposit.
                </Styled_H3>
              </GetGiftFasterContent>

              <WhenYouClaim md={12}>
                <Styled_H2>
                  When you claim your charity page, you can:
                </Styled_H2>

                <Row>
                  {ListItemData.map((item, i) => {
                    i *= 100;
                    return (
                      <Col md={4} key={item.text}>
                        <ListWrap
                          role="list"
                          data-sal="fade"
                          data-sal-duration="1000"
                          data-sal-delay={i}
                        >
                          <ListItem role="listitem">
                            <Content>{item.text}</Content>
                          </ListItem>
                        </ListWrap>
                      </Col>
                    );
                  })}
                </Row>
              </WhenYouClaim>
            </Row>
          </Col>
        </GetGiftFaster>
      </Container>
    </SectionWrap>
  );
};

export default GetGiftsSection;
