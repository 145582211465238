import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { StyledH2, StyledH3 } from "../../styles/globalStyles";

const SectionWrap = styled.section`
  padding: 70px 0;

  @media only screen and (max-width: 767px) {
    text-align: center;
  }
`;

const StyledRow = styled(Row)`
  @media only screen and (max-width: 767px) {
    margin-right: 0;
    margin-left: 0;
  }
`;

const Styled_H2 = styled(StyledH2)`
  font-size: 24px;
  line-height: 29px;
`;

const Styled_H3 = styled(StyledH3)`
  font-family: ProximaNova-Regular;
  font-size: 18px;
  line-height: 28px;
  margin: 0 0 15px;
`;

const SupportPeople = () => {
  return (
    <SectionWrap>
      <Container>
        <Row
          className="justify-content-md-center"
          data-sal="fade"
          data-sal-duration="1000"
          data-sal-delay="400"
        >
          <Col md={12} lg={10}>
            <StyledRow className="align-items-center">
              <Col md={6} className="order-2 order-md-0 mt-30 mt-md-0">
                <Styled_H2>
                  We help people support their favourite charities, including
                  yours
                </Styled_H2>
                <Styled_H3>
                  If you’re a registered charity in Canada, donors can give to
                  you through Charitable Impact.
                </Styled_H3>
                <Styled_H3>
                  Our searchable catalogue helps people find and learn about
                  charities, and includes information published by the Canada
                  Revenue Agency. And, our online platform makes it even easier
                  for people to support the charities and causes that matter
                  most to them.
                </Styled_H3>
              </Col>

              <Col md={6}>
                <img
                  src="/for-charities/illustration.png"
                  alt=""
                  loading="lazy"
                />
              </Col>
            </StyledRow>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default SupportPeople;
