import React from "react";
import styled from "styled-components";
import { VCenter } from "../../styles/globalStyles";

const SectionWrap = styled.section`
  background-position: bottom;
  background-size: cover;
  min-height: 400px;
  overflow: hidden;
  position: relative;
  ${VCenter}

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    min-height: 440px;
  }
`;

const TileImages = styled.div`
  background-position: 50% !important;
  height: 520px;
  position: absolute;
  top: -82px;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  width: 50%;
  z-index: 1;

  &.left {
    left: 0;
    text-align: left;
    top: 20%;
    z-index: 1;

    @media only screen and (max-width: 767px) and (min-width: 320px) {
      top: 20%;
    }
  }

  &.right {
    right: 0;
    text-align: right;
    top: 0;

    .img1 {
      height: 92px;
      width: 82px;
      -o-object-fit: cover;
      object-fit: cover;

      @media only screen and (max-width: 1199px) {
        height: 52px;
        width: 60px;
        margin-left: 48px;
      }
    }

    .test {
      @media only screen and (max-width: 1199px) and (min-width: 991px) {
        position: absolute;
        right: -13px;
      }
    }

    .line-test {
      @media only screen and (max-width: 1199px) and (min-width: 991px) {
        position: absolute;
        right: 138px;
        top: 45px;
      }
    }
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    top: 20%;
  }
`;

const BannerMobile1 = styled.img`
  @media only screen and (max-width: 767px) and (min-width: 320px) {
    display: none;
  }
`;

const SmallDot1 = styled.span`
  position: absolute;
  left: 260px;
  bottom: 175px;
  background-color: #db0163;
  padding: 6px 7px 6px 8px;

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    display: none;
  }
`;

const VTop = styled.div`
  vertical-align: bottom;

  span {
    vertical-align: top;
    display: inline-block;

    @media only screen and (max-width: 991px) and (min-width: 768px) {
      display: initial;
      vertical-align: top;
    }

    @media only screen and (max-width: 767px) and (min-width: 320px) {
      display: initial;
      vertical-align: top;
    }
  }

  &.all-right-banner {
    position: relative;
  }
`;

const BannerImgLeft1 = styled.span`
  left: 10%;
  position: absolute;
  top: 23.4%;
`;

const YellowDot = styled.span`
  position: absolute;
  right: 341px;
  top: 77px;
  background-color: #f7ba4a;
  padding: 6px 7px 6px 8px;

  @media only screen and (max-width: 1199px) and (min-width: 991px) {
    display: none !important;
  }

  @media only screen and (max-width: 991px) {
    background: transparent;
  }
`;

const BannerImgRight1 = styled.img`
  left: 290px;
  position: absolute;
  top: 0;
  z-index: 1;
  max-width: none;

  @media only screen and (max-width: 1650px) and (min-width: 1401px) {
    left: 22%;
  }

  @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    left: 11%;
  }
`;

const BannerImgRight2 = styled.img`
  left: 170px;
  position: relative;
  top: -59px;

  @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    left: 92px;
    position: relative;
    top: -3px;
  }

  @media only screen and (max-width: 991px) and (min-width: 768px) {
    left: 162px;
    top: 0;
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    left: 232px;
    top: -8px;
  }

  @media only screen and (max-width: 420px) {
    left: 123px;
    top: -80px;
  }
`;

const BannerImgRight3 = styled.img`
  position: relative;
  top: -10px;
  left: -8px;

  @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    left: -113px;
  }

  @media only screen and (max-width: 991px) and (min-width: 768px) {
    left: 208px;
    top: -377px;
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    height: 212px;
    left: -7px;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 64%;
    width: 182px;
  }

  @media only screen and (max-width: 420px) {
    left: -4px;
    top: 61%;
    width: 145px;
  }
`;

const BannerLine = styled.span`
  @media only screen and (max-width: 767px) and (min-width: 320px) {
    background-color: #f7ba4a;
    height: 212px;
    left: -175px;
    position: absolute;
    top: 65%;
    width: 23px;
  }

  @media only screen and (max-width: 420px) {
    background-color: #f7ba4a;
    height: 212px;
    left: -147px;
    position: absolute;
    top: 61%;
    width: 16px;
  }
`;

const BannerImgRight4 = styled.img`
  position: relative;
  top: -1px;

  @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    top: -384px;
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    display: block !important;
    position: absolute;
    right: 100%;
    top: 65%;
    left: -135px;
  }

  @media only screen and (max-width: 420px) {
    left: -123px;
    display: block !important;
    position: absolute;
    right: 100%;
    top: 61%;
  }
`;

const BannerContent = styled.div`
  display: block;
  margin: 110px auto auto;
  padding: 1rem;
  position: relative;
  text-align: center;
  width: 520px;
  z-index: 999;
  margin-top: 69px;
  width: auto;

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    width: 320px;
    padding: 0;
  }

  @media only screen and (max-width: 420px) {
    width: 270px;
    margin-top: 69px;
    padding: 0;
  }
`;

export const BannerHeading = styled.h1`
  font-size: 40px;
  color: #424242;

  @media only screen and (max-width: 1199px) {
    font-size: 30px;
  }
`;

const BannerSection = () => {
  return (
    <SectionWrap className="d-md-block" id="charitable-charities-banner">
      <TileImages>
        <span className="animate__animated animate__fadeInUp animate__slow animate__delay-1s">
          <BannerMobile1
            src="/for-charities/photo-1.jpg"
            className="d-md-block"
          />
          <SmallDot1 className="d-md-block"></SmallDot1>
        </span>
      </TileImages>

      <TileImages className="left">
        <VTop>
          <BannerImgLeft1 className="text-right">
            <img
              src="/for-charities/shape-1.png"
              className="d-none d-md-block"
              alt=""
              loading="lazy"
            />
          </BannerImgLeft1>
        </VTop>
      </TileImages>

      <TileImages className="right">
        <VTop className="all-right-banner">
          <span className="animate__animated animate__fadeInUp animate__slow">
            <BannerImgRight1
              src="/for-charities/bar-1.png"
              className="d-none d-lg-block"
            />
          </span>
          <span className="animate__animated animate__fadeInDown animate__slow animate__delay-1s img1">
            <YellowDot />
          </span>
          <span className="pe-1 line-test">
            <BannerImgRight2
              src="/for-charities/shape-2.png"
              className="d-md-block"
            />
          </span>
          <span className="animate__animated animate__fadeInDown animate__slow animate__delay-1s pe-1 test">
            <BannerImgRight3
              src="/for-charities/photo-2.png"
              className="d-md-block"
            />
          </span>
          <BannerLine />
          <span className="animate__animated animate__fadeInRight animate__slow animate__delay-1s">
            <BannerImgRight4
              src="/for-charities/photo-3.jpg"
              className="d-none d-xl-block"
            />
          </span>
        </VTop>
      </TileImages>

      <BannerContent id="charitableCharitiesBannerContent">
        <BannerHeading>Charitable Impact for charities</BannerHeading>
      </BannerContent>
    </SectionWrap>
  );
};

export default BannerSection;
